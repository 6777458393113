/* src/assets/css/fonts.css */

/* Regular weight font */
@font-face {
    font-family: 'Geist';
    src: url('../Geist-v1.4.01/webfonts/Geist-Regular.woff2') format('woff2');
    font-weight: 400;  /* Regular weight */
    font-style: normal;
    font-display: swap;
  }
  
  /* Medium weight font */
  @font-face {
    font-family: 'Geist';
    src: url('../Geist-v1.4.01/webfonts/Geist-Medium.woff2') format('woff2');
    font-weight: 500;  /* Medium weight */
    font-style: normal;
    font-display: swap;
  }
  
  /* Light weight font */
  @font-face {
    font-family: 'Geist';
    src: url('../Geist-v1.4.01/webfonts/Geist-Light.woff2') format('woff2');
    font-weight: 300;  /* Light weight */
    font-style: normal;
    font-display: swap;
  }
  
  /* Extra Light weight font */
  @font-face {
    font-family: 'Geist';
    src: url('../Geist-v1.4.01/webfonts/Geist-ExtraLight.woff2') format('woff2');
    font-weight: 200;  /* Extra Light weight */
    font-style: normal;
    font-display: swap;
  }
  
  /* Bold weight font */
  @font-face {
    font-family: 'Geist';
    src: url('../Geist-v1.4.01/webfonts/Geist-Bold.woff2') format('woff2');
    font-weight: 700;  /* Bold weight */
    font-style: normal;
    font-display: swap;
  }
  
  /* Extra Bold weight font */
  @font-face {
    font-family: 'Geist';
    src: url('../Geist-v1.4.01/webfonts/Geist-ExtraBold.woff2') format('woff2');
    font-weight: 800;  /* Extra Bold weight */
    font-style: normal;
    font-display: swap;
  }
  
  /* Black weight font */
  @font-face {
    font-family: 'Geist';
    src: url('../Geist-v1.4.01/webfonts/Geist-Black.woff2') format('woff2');
    font-weight: 900;  /* Black weight */
    font-style: normal;
    font-display: swap;
  }
  